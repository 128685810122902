<template>
  <VSkeletonLoader
    fluid
    type="table-heading,card"
    :loading="isLoading"
  >
    <VRow class="pa-5">
      <VCol cols="12">
        <VSheet 
          color="info"
          height="136px"
          class="rounded-lg text-center"
        >
          <img 
            class="mb-n1"
            style="margin-top: 14px;"
            :src="require('../../images/free-trial-unlocked.png')"
          >
          <br>
          <div class="text-h5 font-weight-bold white--text">
            30-Day Free Trial Unlocked
          </div>
          <div 
            class="white--text" 
            style="font-size: 18px;"
          >
            No credit card required
          </div>
        </VSheet>
      </VCol>
      <VCol 
        cols="12"
        class="font-weight-black"
        style="font-size: 28px;"
      >
        Success!
      </VCol>
      <VCol cols="12">
        <div 
          class="text-body-1"
          style="color: #4F4F4F;"
        >
          Lucky duck -- you’re on a 30-day free trial of our <span class="font-weight-bold">{{ plan.title }}</span> plan. It’s got everything you need (and nothing you don’t) to run programs efficiently and scale. That includes:
        </div>
      </VCol>
      <VCol 
        cols="12" 
        class="pt-0"
      >
        <p
          v-for="(feature, index) in features"
          class="text-subtitle-1 mb-2"
          :key="index"
        >
          <VIcon 
            color="info" 
            size="20px"
            class="pr-2 mb-1"
          >
          mdi-check
          </VIcon>
          {{ feature }}
        </p>
      </VCol>
      <VCol cols="12">
        <VBtn
          block
          color="primary"
          height="54px"
          class="black--text"
          style="border-radius: 9px; box-shadow: 0px 3px 6px 0px #00000033 !important;"
          @click="$emit('close')"
        >
          <span 
            class="subtitle-1"
            style="font-weight: 600; color: #333333;"
          >
            Great! Let’s Get Started
          </span>
        </VBtn>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "FreeTrialStart",
  data() {
    return {
      isLoading: true,
      features: [
        "Central Hub for participants and stakeholders",
        "Participant profiles",
        "Survey and data collection",
        "Application management and tracking",
        "Intake and registration",
        "Content library and storage",
        "Unlimited courses",
        "Quizzes and assessments",
        "Impact tracking and analysis",
        "Chat and messaging",
        "Unlimited program participants and facilitators",
      ],
      plan: {
        title: null,
        price: 0,
        price_yearly: 0,
      },
    };
  },
  created() {
    this.getBillingPlans();
  },
  methods: {
    ...mapActions({
      doGetBillingPlans: "payment/getPlans",
    }),
    getBillingPlans() {
      this.doGetBillingPlans().then((result) => {
        // Currently, we are going for single plan only. So some static information are used on the FE.
        this.plan = result[0];
        this.isLoading = false;
      });
    },
  },
}
</script>
